export const NO_ITEM_WITH_THAT_ID = 'No item with that ID';
export const ITEM_IS_NOT_AVAILABLE_FOR_PURCHASE = 'This item is not available for purchase anymore';
export const NOT_ENOUGH_BALANCE = 'Amount withdrawn must be less than or equal than the balance of the Vault';
export const UNABLE_TO_SIGN_TRANSACTION = 'Unable to sign transaction';
export const TRANSACTION_WAS_EXECUTING  = 'transaction was executing for too long 10133us';
export const TRANSACTION_EXPIRED  = 'expired transaction';
export const BILLED_CPU  = 'billed CPU time';
export const NO_RAM  = 'has insufficient ram';
export const NO_BALANCE  = 'overdrawn balance';
export const NO_BALANCE_SDM  = 'no balance object found';
export const ITEM_ANNOUNCED  = 'You have already announced a sale for these assets';
